<template>
  <div>
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <div>
        <div class="heading-sec">
          <div class="row">
            <div class="col-md-6 column">
              <div class="heading-profile">
                <h3>Inquiry Details for Hotel</h3>
              </div>
            </div>
          </div>
        </div>
        <!-- Top Bar Chart -->
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="table-area">
                  <div class="widget-title">
                    <div class="float-right"></div>
                  </div>
                  <div class="form-section">
                    <div class="form-detail pt-3">
                      <b-row>
                        <b-col md="6">
                          <b-input
                            placeholder="User Name"
                            v-model="inquiry.name"
                            readonly
                          ></b-input>
                        </b-col>
                        <b-col md="6">
                          <b-input
                            type="text"
                            placeholder="Date of Inquire"
                            v-model="inquiry.enquiryCreatedAt"
                            readonly
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6">
                          <b-input
                            type="email"
                            placeholder="Email Id"
                            class="mt-4"
                            v-model="inquiry.email"
                            readonly
                          ></b-input>
                        </b-col>
                        <b-col md="6">
                          <b-input
                            type="text"
                            placeholder="Phone No"
                            class="mt-4"
                            v-model="inquiry.phone"
                            maxlength="10"
                            readonly
                          ></b-input>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6">
                          <b-input
                            type="text"
                            placeholder="Hotel Name"
                            class="mt-4"
                            v-model="inquiry.hotel.name"
                            readonly
                          ></b-input>
                        </b-col>
                        <b-col md="6">
                          <b-input
                            type="text"
                            placeholder="Message"
                            class="mt-4"
                            v-model="inquiry.message[0].messageToSend"
                            maxlength="10"
                            readonly
                          ></b-input>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import headerCustom from "@/components/Header.vue";
import apiservice from "@/service/apiservice";
import moment from "moment";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      inquiry: {
        email: "",
        phone: "",
        enquiryCreatedAt: "",
        hotel: {
          name: ""
        },
        message: [
          {
            messageToSend: ""
          }
        ]
      },
      id: this.$route.params.id
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.viewInquiryDetail(this.id);
  },
  methods: {
    viewInquiryDetail() {
      apiservice.getOneUserEnquiriesByIdForAdmin({ id: this.id }, data => {
        if (data.status === 200) {
          this.inquiry = data.data;
        } else {
        }
      });
    }
  }
};
</script>

<style></style>
